@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.test{
    white-space: pre-line;
    vertical-align: bottom;
}

/* 
/////// DESKTOP CSS ///////
*/
.flex-container {
    display: flex;
    flex-direction: row;
    position: relative;
    font-family: 'Prompt', sans-serif;
    animation: fadeInAnimation 1s linear;
}

.leftside,
.rightside {
    height: 100vh;
    width: 50vw;
}

/* 
    DETAILS SIDE 
*/
.leftside {
    display: flex;
}

.details-container {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 2vh;
}

.details-title {
    height: 45vh;
}

.child-details {
    height: fit-content;
}

.description-box {
    margin-left: 7px;
    text-align: left;
    font-size: 1.3rem;
    white-space: pre-line;
    vertical-align: bottom;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.page-title {
    font-size: 4rem;
    text-align: left;
}

/* social links */
.social-bar img {
    height: 2rem;
    padding-right: 0.5rem;
    opacity: 35%;
}

.social-bar img:hover {

    opacity: 65%;
    cursor: pointer;
}

.social-bar {
    padding-left: 0.25rem;
    display: flex;
    justify-content: left;
    padding-bottom: 10px;
}

/* TABLE */
.info-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    text-align: left;

}


.info-table tr {
    font-size: 1.3rem;
    border-top: 1px solid black;
    overflow: hidden;

}

.info-table td {
    padding-top: 10px;
    padding-bottom: 10px;
}


/* top bar*/
.home-bar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.home-bar:hover {
    cursor: pointer;
    color: gray;
}



/* 
    MEDIA SIDE 
*/

.rightside {
    background-color: #b5c7c2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
}

.header-image {
    display: none;
}

.rightside img {
    width: 80%;
}

.image-list {
    display: none;
}


/* custom background */
.rightside{
    color: white;
}

.about .rightside {
    background-color: #b5c7c2;
}

.capstone .rightside {
    background-color: #9b9583;
}

.jobapp .rightside {
    background-color: #8388a1;
}

.wordle .rightside {
    background-color: #5e695f;
}

.idlegame .rightside {
    background-color: #4c7072;
}

.portfolio .rightside {
    background-color: #9b9583;
}

/* 
transitions
*/
.fullscreen-transition-enter {
    opacity: 0;

}

.fullscreen-transition-enter-active {
    background-color: black;
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fullscreen-transition-enter-done{
    background-color: black;

    opacity: 1;
}

.fullscreen-transition-exit {
    background-color: black;

    opacity: 1;
}

.fullscreen-transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.fullscreen-transition-exit-done{
    opacity: 0;
}

/* 
/////// MOBILE CSS ///////
*/

@media screen and (max-width:800px) {
    .flex-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .rightside,
    .leftside,
    .details-title {
        height: fit-content;
    }

    .details-title {
        margin-bottom: 20%;
    }

    .about .details-title {
        margin-bottom: 0;
    }

    .rightside {
        display: none;
    }

    .leftside {
        width: 100%;
    }

    .page-title {
        font-size: 3rem;
        margin-bottom: 10px;
    }

    .about .header-image {
        display: flex;
        justify-self: center;
        width: 100%;
        padding-bottom: 1rem;
        left: auto;
        right: auto;
    }

    .about .image-list {
        display: none;
    }

    .image-list img {
        width: 100%;
        /* box-shadow: 3px 3px 10px 7px rgb(228, 228, 228); */
        ;
    }

    .image-list-item {
        /* margin-top: 1rem; */
        text-align: center;
    }

    .image-list {
        list-style-position: inside;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .image-list-caption{
        padding-bottom: 1em;
    }

    .social-bar {
        display: none;
    }

    .about .social-bar {
        display: flex;
    }

    .info-table tr {
        font-size: 1rem;
    }

    .info-table tr td:nth-of-type(1) {
        width: 30%;
    }

    .description-box {
        font-size: 1.4rem;
        margin-bottom: 0;
    }
}