.image-slider {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    position: relative;

    min-width: 50vw;
    max-width: 100vw;

    height: 100vh;
    width: auto;
}

::selection{
    background-color: #e8e80e;
    color: black;
}



/* image column */
.image-column {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 1em;
    margin-bottom: 1em;

    width: 100%;
    height: calc(100vh-2em);

    transition: width 1s ease-in-out 1s;
    
}

#image-header,
#image-description {
    text-align: center;
    position: absolute;
    color: white;
}

#image-header {
    top: 0;
    display: inline-flex;
}

#image-description {
    bottom: 0;
    z-index: 4;
    cursor: text;
}

/* images */
.image-wrapper {
    display: flex;
    position: relative;
    text-align: left;
    overflow: hidden;

    /* 3: 2*/
    width: 45vw;
    height: 30vw;
    transition: transform 300ms ease-in-out;
}

.fullscreen-true .image-wrapper {
    /* ratio 3:2 */
    transform: scale(1.1,1.1);
    min-height: 540px;
    min-width: 810px;
}

.image-wrapper img {
    width: 100%;
    height: 100%;
    display: block; 
    position: absolute;
    object-fit: fit;
    user-select: none;
}


/*  ============ 
CAROUSEL BUTTONS 
 ============*/
.slider-button-container{
    display: flex;

    width: 50vw;
    height: 100vh;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

}

.fullscreen-true .slider-button-container {
    width: 100vw;
}

.slider-button {
    width: 50%;
}

button {
    border: none;
    border-radius: 0;
    background: none;
    font: inherit;
    padding: 0;
    cursor: pointer;
    color: currentColor;
    z-index: 4;
}

/* FULLSCREEN */
.fullscreen-true{
    width: 100vw;
}

#showFullscreen{
    margin-left: 0.9em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#exit-fullscreen{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    user-select: none;
}

#exit-fullscreen:hover{
    opacity: 50%;
}

#exit-fullscreen img {
    width: 20px;
    height: 20px;
    color: white;
}

.small-arrow{
    height: 0.8em;
    width: auto;
    margin-right: 0.8em;
    display: inline;
}

/* SLIDER ACTION BUTTONS*/

.slider-button {
    z-index: 3;
    width: 50%;
    height: 100vh;
    cursor: url('../../../Assets/Arrows/arrow-left-white.svg'), auto;
}

.prev {
    cursor: url('../../../Assets/Arrows/arrow-left-white.svg'), auto;
}
.next {
    cursor: url('../../../Assets/Arrows/arrow-white.svg'), auto;
}


/* ===== CAROUSEL TRANSITIONS ======*/
.slide-right-enter {
    transform: translateX(100%);
}

.slide-right-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.slide-right-exit {
    transform: translateX(0%);
}

.slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.slide-left-enter {
    transform: translateX(-100%);
}

.slide-left-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.slide-left-exit {
    transform: translateX(0%);
}

.slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
}


/* ========= FULL SCREEN TRANSITIONS ========= */

.test-enter {
    width: 50vw;
    position: absolute;
    right: 0;

}

.test-enter-active {
    width: 100vw;
    transition: width 500ms;
    position: absolute;
    right: 0;
}

.test-enter-done {
    width: 100vw;
    height: 100%;
    position: absolute;
    right: 0;

}
/* === Exit Transition === */
.test-exit {
    width: 100vw;
    height: 100%;
    position: absolute;
    right: 0;
}

.test-exit-active {
    width: 50vw;
    height: 100%;
    position: absolute;
    right: 0;

    transition: width 500ms;
}

.test-exit-done {
    width: 50vw;
    height: 100%;
    position: absolute;
    right: 0;


}

/* ===== CUSTOM BACKGROUND ======*/
.image-slider{
    background-color: #9b9583;
}

.about .image-slider {
    background-color: #b5c7c2;
}

.capstone .image-slider {
    background-color: #9b9583;
}

.portfolio .image-slider {
    background-color: #9b9583;
}

.jobapp .image-slider {
    background-color: #8388a1;
}

.wordle .image-slider {
    background-color: #5e695f;
}

.idlegame .image-slider {
    background-color: #4c7072;
}
