.TechBadge{
    font-size: smaller;
    border: 1px solid black;
    padding: 3px;
    margin: 3px;
    color: black;

}

.BadgeRow {
    display: flex;
    flex-wrap: wrap;
}

/* COLOR PALETTE 
#B7BCBF
#E8D4C6
#C9C9C2
#F2DBD5
*/


.badge-React, .badge-Reactjs{
    background-color: #ddbda6;
    
}
.badge-ASPNET {
    background-color: #CDD5C6;
}

.badge-AzureDevOps {
    background-color: #C9C9C2;
}

.badge-AzureDataLake,
.badge-OpenAI{
    background-color: #d8d0ba;
}

.badge-FirebaseRealtime{
    background-color: #E8CDC5;
}

.badge-Angular, .badge-FirebaseStorage{
    background-color: #c5d2d9;
}

.badge-GoogleCloudSQL {
    background-color: #cdb8b2;
}

.badge-NodeJSExpress{
    background-color: #CDD5C6;
}