.loader-container {
    background-color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation 0.5s linear;
  }
  
  .loader {
    width: 8px;
    height: 48px;
    display: block;
    margin: auto;
    left: -20px;
    position: relative;
    border-radius: 2px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: 20px 0 rgba(170, 170, 170, 0.25), 40px 0 rgb(170,170,170), 60px 0 rgb(170,170,170);
    }
    50% {
      box-shadow: 20px 0 rgb(170,170,170), 40px 0 rgba(170, 170, 170, 0.25), 60px 0 rgb(170,170,170);
    }
    100% {
      box-shadow: 20px 0 rgb(170,170,170), 40px 0 rgb(170,170,170), 60px 0 rgba(170, 170, 170, 0.25);
    }
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }