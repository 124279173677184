@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/* 
/////// DESKTOP CSS ///////
*/

.home-container {
    display: grid;
    height: 100%;
    animation: fadeInAnimation 0.75s linear;
}

.top {
    padding-bottom: 5em;
}



/* 
/////// MOBILE CSS ///////
*/

@media screen and (max-width:800px) {
    .home-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        animation: fadeInAnimation 0.75s linear;
    }

    .top,
    .bottom {
        height: fit-content;
        width: 100%;
    }

    .top {
        padding-bottom: 0em
    }

    .data-table .type,
    .location {
        display: none;
    }

    .data-table .name,
    .date {
        width: 50%;
    }

    .data-table td {
        font-size: 1rem;
    }

    .preview:hover .hide-image {
        display: none;
    }
}