.header-carousel-container{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 0;
    }

.header-image-wrapper{
    height: 100%;
    width: 50vw;
    position: relative;
    min-height: 23em;
    overflow: hidden;
}

.header-image-wrapper img{
    position: absolute;
    object-fit: cover;
    overflow: hidden;
    height: 100%; 
    width: 100%;
}

#preview-caption{
    width: 100%;
    display: grid;
    grid-template-columns: 23% 30% 47%;
    position: relative;
}

.header-carousel-arrows img{
    padding-left: 1em;
    width: 1em;
}

#caption-project-link img{
    width: 1em;
    padding-right: 0.25em;
}

#caption-project-link:hover{
    opacity: 50%;
}

#caption-project-link {
    color: black;
    text-decoration: none;
}

.header-prev:hover, .header-next:hover {
    cursor: pointer;
    opacity: 50%;
}

#preview-caption div{
    padding-top: 0.5em;
}

#carousel-project{
    font-weight: 500;
}

/* ===== CAROUSEL TRANSITIONS ======*/
.slide-right-enter {
    transform: translateX(100%);
}

.slide-right-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.slide-right-exit {
    transform: translateX(0%);
}

.slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.slide-left-enter {
    transform: translateX(-100%);
}

.slide-left-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.slide-left-exit {
    transform: translateX(0%);
}

.slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
}

@media screen and (max-width:800px) {
    .header-image-wrapper{
        width: 100%;
        margin-top: 1em;
    }

    #preview-caption{
        width: 100%;
        display: grid;
        grid-template-columns: 30% 30% 40%;
        position: relative;
    }

    #open-project{
        height: 3em;
    }

}