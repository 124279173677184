.header-container{
    display: flex;
    flex-direction: row;

}

.header-description{
    background-color: white;
    height: fit-content;
    width: 50%;
    padding-left: 1rem;
    padding-top: 1rem;
}

.header-carousel {
    width: 50%;
}

.intro-description {

    font-size: 1.9rem;
    text-align: left;
}

.nav-list {
    text-transform: lowercase;
    display: flex;
    flex-direction: row;
    font-size: 1.9rem;
}

.nav-list a, .nav-list div {
    color: black;
    display: flex;
    align-items: center;
    padding-right: 0.25em;
    text-decoration: underline;
}

.nav-list a, .nav-list div {
    text-align: center;
}

.nav-list a:hover, .nav-list div:hover {
    cursor: pointer;
    color: gray;
}

.nav-list a:visited {
    text-decoration: none;
}

.nav-list a div, .nav-list div div {
    padding-bottom: 0.3rem;
    padding-right: 1rem;
}

#rarrow {
    height: auto;
    width: 1.75rem;
    padding-right: 0.25rem;
}

@media screen and (max-width:800px) {
    .header-container{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }
    .header-carousel{
        width: 100%;
    }

    .header-description{
        width: 100%;
        padding-left:0;
    }

    .intro-description, .nav-list {
        font-size: 1.5em;
        padding-left: 0.5em;
        padding-right: 0.5em
    }

}