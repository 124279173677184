/* ====================
Projects Table
===================== */
.data-table {
    width: 100%;
    height: fit-content;
    border-spacing: 0;
    position: absolute;
    z-index: 6;
    padding: 1em;
}

.data-table th,
td {
    border-bottom: 1px solid #000000;
}

.data-table th {
    font-size: 15px;
    color: #8a8a8a;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
}

.data-table td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.data-table tr {
    font-size: 20px;
}

.data-table tr:hover {
    cursor: pointer;
}

/* only highlight the first column on hover*/
.data-table tr:hover td:nth-of-type(1) {
    color: #636363;
}

.data-table tr td:nth-of-type(1) {
    width: 50%;
}

.data-table tr td:nth-of-type(2),
td:nth-of-type(3) {
    width: 20%;
}

.date {
    text-align: right;
    /* width: 10%; */
}

.name,
.type,
.location {
    text-align: left;
}

/* ====================
Preview/Hidden Image
===================== */
.hide-image img{
    height: 0%;
    width: 0%;
    object-fit: cover;
}

.hide-image {
    position: fixed;
    top: 0;
    right: 0;
    pointer-events: none;
    object-fit: cover;
    display: none;
}

.preview:hover .hide-image {
    display: block;
    z-index: -1;
    height: 100%;
    width: 50%;
}

.preview:hover .hide-image img{
    width: 100%;
    height: 100%;
    z-index: -1;
}

#preview-data{
    width: 0%;
}


/* ====================
Custom preview colors
===================== */
.idlegame .hide-image   {
    background-color: #638482;
}

.portfolio .hide-image {
    background-color: #dbd4b7;

}

.jobapp .hide-image {
    background-color: #aea5ba;

}

.wordle .hide-image {
    background-color: #efe3b3;

}

.capstone .hide-image {
    background-color: #638482;

}

@media screen and (max-width:800px) {
    .preview:hover .hide-image{
        display: none;
    }
}